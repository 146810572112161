<template>
  <div class="mb-4 invoices-wrapper with-nav-tabs">
    <b-form @submit.prevent="createInvoice">
      <div class="settings-block">
        <div class="w-100 d-flex justify-content-between">
          <a class="desktop-layer" href="#" @click.prevent="goSettings">Изменить настройки</a>
          <a class="mobile-layer" href="#" @click.prevent="goSettings">Настройки</a>
          <a href="https://mndr.in/invoices" target="_blank">Инструкция</a>
        </div>
        <hr class="w-100" />
        <div class="header w-100">
          <div class="header-text w-100">
            <div class="w-100 d-flex justify-content-between">
              <h5 class="desktop-layer">Данные заказа</h5>
              <p class="mobile-layer font-weight-bold">Данные заказа</p>
              <a class="desktop-layer" href="#" @click.prevent="clearForm">Очистить форму</a>
              <a class="mobile-layer" href="#" @click.prevent="clearForm">Очистить</a>
            </div>
          </div>
        </div>
        <b-form-group label="Номер заказа (необязательное)" class="payment-input">
          <b-form-input
            :state="validate.orderNumber"
            @focus="validate.orderNumber = null"
            v-model="invoiceData.orderNumber"
            :placeholder="'Номер заказа'"></b-form-input>
        </b-form-group>
        <b-form-group label="Email клиента (необязательное)" v-if="settings" class="payment-input">
          <b-form-input
            :state="validate.customerEmail"
            @focus="validate.customerEmail = null"
            v-model="invoiceData.customerEmail"
            :disabled="invoiceData.customerEmailHide"
            placeholder="Email клиента"></b-form-input>
        </b-form-group>
        <b-form-group label="Телефон клиента (необязательное)" v-if="settings" class="payment-input">
          <b-form-input
            :state="validate.customerPhone"
            @focus="validate.customerPhone = null"
            v-mask="'+7 (###) ###-##-##'"
            v-model="invoiceData.customerPhone"
            :disabled="invoiceData.customerPhoneHide"
            placeholder="Телефон клиента"></b-form-input>
        </b-form-group>

        <div v-for="(field, index) in invoiceData.additionalData" :key="field.name" class="w-100">
          <b-form-group :label="field.name" class="payment-input">
            <b-form-input
              :state="validate.additionalData[index].value"
              @focus="validate.additionalData[index].value = null"
              v-model="field.value"
              :placeholder="invoiceData.additionalData[index].value"></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="settings-block">
        <div class="header w-100">
          <div class="header-text w-100">
            <div class="w-100 d-flex justify-content-between">
              <h5 class="desktop-layer">Корзина</h5>
              <p class="mobile-layer font-weight-bold">Корзина</p>
              <a class="mobile-layer" href="#" @click.prevent v-b-modal.calculator-modal>Калькулятор</a>
              <div>
                <a class="desktop-layer" style="margin-right: 30px" href="#" @click.prevent v-b-modal.calculator-modal>
                  Калькулятор расчетов
                </a>
                <a href="#" @click.prevent="clearCart">Очистить корзину</a>
              </div>
            </div>
          </div>
        </div>

        <div v-for="(item, index) in invoiceData.cart" :key="index" class="cart-item mb-0 w-100">
          <div class="w-100 d-flex justify-content-around align-content-end flex-column flex-sm-row">
            <b-form-group label="Наименование" class="w-50 mr-2">
              <b-form-input
                :state="validate.cart[index].name"
                @focus="validate.cart[index].name = null"
                v-model="item.name"
                placeholder="Введите название товара или услуги"></b-form-input>
            </b-form-group>
            <b-form-group label="Стоимость" class="mr-2">
              <b-form-input
                @keydown="validateInput($event)"
                :state="validate.cart[index].cost"
                @focus="validate.cart[index].cost = null"
                @input="setFormattedCost($event, index)"
                :value="getFormattedCost(index)"
                type="text"
                placeholder="0.00"></b-form-input>
            </b-form-group>
            <b-form-group label="НДС" class="w-25">
              <b-form-select :options="optionsVat" v-model="item.vat"></b-form-select>
            </b-form-group>
            <b-form-group class="text-center">
              <label class="text-white d-none d-sm-block">&nbsp;</label>
              <button class="btn-delete" @click.prevent="removeCartItem(index)"></button>
            </b-form-group>
          </div>
        </div>
        <a class="pb-4" href="#" @click.prevent="addCartItem">
          <img alt="Add item" src="~@/assets/images/icon-plus.svg" height="14" />
          Добавить товар
        </a>
      </div>

      <div class="settings-block pb-3">
        <div class="w-100">
          <div v-if="enableInfo" class="d-flex justify-content-start align-items-start">
            <mdicon name="information-outline" height="20" />
            <span>
              У вас нет настроенных способов оплаты. Вы можете перейти в
              <a href="#" @click.prevent="goSettings">настройки</a>
              и сохранить выбранные способы оплаты, что бы каждый раз их не выбирать при выставлении новых счетов.
            </span>
          </div>
          <a class="btn px-0 text-primary" v-if="enableExtendSettings" @click.prevent="enableExtendSettings = false">
            Скрыть дополнительные настройки
            <mdicon class="text-primary" name="chevron-up" height="20" />
          </a>
          <a class="btn px-0 text-primary" v-else @click.prevent="enableExtendSettings = true">
            Показать дополнительные настройки
            <mdicon class="text-primary" name="chevron-down" height="20" />
          </a>
        </div>
        <div class="w-100" v-if="enableExtendSettings">
          <div class="header mb-0">
            <div class="header-text">
              <h5>Способы оплаты</h5>
            </div>
          </div>

          <div class="w-100">
            <b-form-checkbox
              :state="validate.ExtendSettings.credit && isVisible()"
              @change="clearValidateExtendSettings"
              v-model="settings.payment_methods_settings.credit.enable"
              switch
              :disabled="!isVisible()">
              Кредит / Рассрочка{{ !isVisible() ? ' (недостаточно прав)' : '' }}
            </b-form-checkbox>

            <div v-if="settings.payment_methods_settings.credit.enable && isVisible()" class="mt-3">
              <b-form-group class="payment-input d-block">
                <legend>
                  Кредитный продукт
                  <i
                    v-b-tooltip.hover
                    title="Выберите кредитные продукты, которые будут по умолчанию доступны клиентам при выставлении счета">
                    <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14" />
                  </i>
                </legend>
                <b-form-select
                  :options="hl_merchants_options"
                  v-model="settings.payment_methods_settings.credit.product_id"
                  @change="handleMerchantIdChange"></b-form-select>
              </b-form-group>
              <b-form-group class="payment-input d-block">
                <legend>
                  Доступные сроки
                  <i
                    v-b-tooltip.hover
                    title="Выберите срок(и) заявок на кредит / рассрочку, которые будут по умолчанию доступны клиентам при выставлении счета.">
                    <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14" />
                  </i>
                </legend>
                <multiselect
                  id="some"
                  v-model="term_in_months"
                  :options="term_in_months_options_ms"
                  :multiple="true"
                  :searchable="false"
                  track-by="value"
                  label="text"
                  :deselect-label="$i18n.t('service.remove')"
                  :select-label="$i18n.t('service.add')"
                  :selected-label="$i18n.t('service.optionSelected')"
                  placeholder="Выберите сроки для выбора при оформлении заявки"></multiselect>
              </b-form-group>
            </div>

            <b-form-checkbox
              :state="validate.ExtendSettings.card"
              @change="clearValidateExtendSettings"
              v-model="settings.payment_methods_settings.card.enable"
              switch>
              Карта РФ
            </b-form-checkbox>

            <div v-show="settings.payment_methods_settings.card.enable" class="mt-3">
              <b-form-group class="payment-input d-block">
                <legend>
                  Выберите проект
                  <i v-b-tooltip.hover title="Укажите merchant ID">
                    <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14" />
                  </i>
                </legend>
                <b-form-select :options="get_merchants" v-model="settings.payment_methods_settings.card.merchant_id"></b-form-select>
              </b-form-group>
            </div>

            <b-form-checkbox
              :state="validate.ExtendSettings.card2"
              @change="clearValidateExtendSettings"
              v-model="settings.payment_methods_settings.card2.enable"
              switch>
              Иностранная карта
            </b-form-checkbox>

            <div v-show="settings.payment_methods_settings.card2.enable" class="mt-3">
              <b-form-group class="payment-input d-block">
                <legend>
                  Выберите проект
                  <i v-b-tooltip.hover title="Укажите merchant ID">
                    <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14" />
                  </i>
                </legend>
                <b-form-select :options="get_merchants" v-model="settings.payment_methods_settings.card2.merchant_id"></b-form-select>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>

      <div v-if="invoice" class="settings-block">
        <div class="w-100">
          <b-form-group class="w-100" label="Ссылка на оплату">
            <b-input-group class="w-100">
              <b-form-input
                class="cursor-pointer"
                :value="getPaymentUrl()"
                readonly
                @click.prevent="goToUrl(getPaymentUrl())"></b-form-input>
              <b-input-group-append>
                <b-button @click="copyUrl" variant="outline-secondary">
                  <mdicon name="file-multiple-outline" height="20" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <b-button type="submit" variant="primary" block :disabled="apiResponseReceived || !!invoice">
            <b-spinner style="height: 14px; width: 14px" v-if="apiResponseReceived"></b-spinner>
            <span v-else>Создать счет</span>
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { VueMaskDirective } from 'v-mask'
import { VueTelInput } from 'vue-tel-input'
import { float } from 'quill/ui/icons'
import { getTermOptions } from '../../../plugins/utils'

function isNumeric(n) {
  return /^-?\d*[.,]?\d*$/.test(n)
}

const default_options = [{ value: 'none', text: '- не выбрано -' }]

export default {
  directives: {
    mask: VueMaskDirective,
  },
  components: { VueTelInput, Multiselect },
  data() {
    return {
      term_in_months: [],
      hl_merchants_options: default_options,
      term_in_months_options: default_options,
      invoiceData: {
        orderNumber: '',
        customerEmail: '',
        customerEmailHide: false,
        customerPhone: '',
        customerPhoneHide: false,
        cart: [{ name: '', cost: 0.0, vat: 'None' }],
        additionalData: [],
      },
      validate: {
        orderNumber: null,
        customerEmail: null,
        customerPhone: null,
        cart: [{ name: null, cost: null }],
        additionalData: [],
        ExtendSettings: {
          credit: null,
          card: null,
          card2: null,
        },
      },
      optionsVat: [
        { value: 'None', text: 'Без НДС' },
        { value: 'Vat0', text: '0%' },
        { value: 'Vat5', text: '5%' },
        { value: 'Vat7', text: '7%' },
        { value: 'Vat10', text: '10%' },
        //{value: 'Vat18', text: 'НДС по ставке 18%'},
        { value: 'Vat20', text: '20%' },
      ],
      enableExtendSettings: false,
      settings: null,
      enableInfo: false,
    }
  },
  computed: {
    notOrderNumber() {
      return (
        this.settings &&
        this.settings.basic_settings &&
        this.settings.basic_settings.order &&
        this.settings.basic_settings.order.requestPayerOrderId
      )
    },
    get_merchants() {
      // Compare merchants and merchants_name
      let merchants = this.$store.state.account.merchants // Example [604,788,974]
      let merchants_name = this.$store.state.account.merchants_name // Example ["ООО Ромашка","ООО ПСП0","ООО ПСП"]
      let options = [{ value: null, text: '- не выбрано -' }]
      if (Array.isArray(merchants) && Array.isArray(merchants_name)) {
        merchants.forEach((merchant, index) => {
          if (merchant !== null && merchants_name[index] !== null) {
            options.push({ value: merchant, text: `${merchant} ${merchants_name[index]}` })
          }
        })
      }
      return options
    },
    apiResponseReceived() {
      return this.$store.state.invoices.isLoading
    },
    invoice() {
      return this.$store.state.invoices.invoice
    },
    term_in_months_options_ms() {
      // Создаем копию массива, чтобы не изменять оригинальный
      let options = [...this.term_in_months_options]

      // Если длина больше 1 и первый элемент 'none', удаляем его
      if (options.length > 1 && options[0].value === 'none') {
        options.shift()
      }

      // Используем Set для отслеживания уже встреченных значений value
      const seen = new Set()

      // Фильтруем массив, оставляя только первые вхождения каждого value
      return options.filter((option) => {
        if (seen.has(option.value)) {
          return false // Пропускаем, если значение уже встречалось
        } else {
          seen.add(option.value) // Добавляем значение в Set
          return true // Сохраняем опцию
        }
      })
    },
  },
  mounted() {
    if (this.$store.state.invoices && this.$store.state.invoices.invoiceCopy) {
      console.log(this.$store.state.invoices.invoiceCopy)
      this.fillFormWithInvoiceData(this.$store.state.invoices.invoiceCopy)
    }
  },
  methods: {
    isVisible() {
      return this.checkRolePresence('clients.life_merchant.basic')
    },
    validateInput(event) {
      // Разрешаем использование клавиш Backspace, Delete, а также стрелок влево и вправо
      const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'End', 'Home']

      if (!/[0-9.,]/.test(event.key) && !allowedKeys.includes(event.key)) {
        event.preventDefault()
      }
      let value = event.target.value
      if ((event.key === '.' || event.key === ',') && value.includes('.')) {
        event.preventDefault()
      }
      if (value.includes('.') && value.split('.')[1].length > 1 && !allowedKeys.includes(event.key)) {
        event.preventDefault()
      }
    },
    getFormattedCost(index) {
      const cost = this.invoiceData.cart[index].cost
      if (!cost) return ''
      return cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    setFormattedCost(value, index) {
      this.invoiceData.cart[index].cost = Number(value.replace(/\s/g, ''))
    },
    async validateAll() {
      this.validateOrderNumber()
      this.validateCustomerEmail()
      this.validateCustomerPhone()
      this.validateExtendSettings()

      this.validate.cart = this.invoiceData.cart.map(() => ({ name: null, cost: null }))
      this.invoiceData.cart.forEach((_, index) => {
        this.validateCartItemName(index)
        this.validateCartItemCost(index)
      })

      this.validate.additionalData = this.invoiceData.additionalData.map((field) => {
        return { value: null }
      })
      this.invoiceData.additionalData.forEach((_, index) => {
        this.validateAdditionalData(index)
      })
    },
    isFormValid() {
      const basicFieldsValid = Object.values(this.validate).every((value) => {
        console.log(value)
        return Array.isArray(value) || (value && value.constructor === Object) ? true : value === null
      })
      // Проверка корзины: name и cost не пустые для каждого товара
      const cartPositionsValid = this.invoiceData.cart.every((item, index) => {
        return this.validate.cart[index].name === null && this.validate.cart[index].cost === null
      })

      const additionalDataValid =
        this.invoiceData.additionalData.length === 0 || this.validate.additionalData.every((item) => item.value === null)
      const extendSettingsValid =
        (this.enableExtendSettings && Object.values(this.validate.ExtendSettings).every((v) => v === null)) || !this.enableExtendSettings

      console.log(basicFieldsValid, additionalDataValid, extendSettingsValid, cartPositionsValid)
      return basicFieldsValid && additionalDataValid && extendSettingsValid && cartPositionsValid
    },

    validateExtendSettings() {
      // Проверка включения хотя бы одного способа оплаты
      const isCreditEnabled = this.settings.payment_methods_settings.credit.enable
      const isCardEnabled = this.settings.payment_methods_settings.card.enable
      const isCard2Enabled = this.settings.payment_methods_settings.card2.enable
      const validateExtendSetting = isCreditEnabled || isCardEnabled || isCard2Enabled

      // Проверка выбранного мерчанта и срока для кредита, если кредит включен
      const isCreditMerchantSelected = (isCreditEnabled && this.settings.payment_methods_settings.credit.product_id) || !isCreditEnabled
      const isCreditTermSelected = (isCreditEnabled && this.term_in_months.length > 0) || !isCreditEnabled

      // Проверка выбранного мерчанта для карты, если карта включена
      const isCardMerchantSelected = (isCardEnabled && this.settings.payment_methods_settings.card.merchant_id) || !isCardEnabled
      const isCard2MerchantSelected = (isCard2Enabled && this.settings.payment_methods_settings.card2.merchant_id) || !isCard2Enabled

      // Обновление состояния валидации
      this.validate.ExtendSettings.credit = validateExtendSetting && isCreditMerchantSelected && isCreditTermSelected ? null : false
      this.validate.ExtendSettings.card = validateExtendSetting && isCardMerchantSelected ? null : false
      this.validate.ExtendSettings.card2 = validateExtendSetting && isCard2MerchantSelected ? null : false

      // Определение, нужно ли включить расширенные настройки на основе валидации
      if (
        !validateExtendSetting ||
        !isCreditMerchantSelected ||
        !isCreditTermSelected ||
        !isCardMerchantSelected ||
        !isCard2MerchantSelected
      ) {
        this.enableExtendSettings = true
      }
    },
    validateOrderNumber() {
      const orderNumber = this.invoiceData.orderNumber
      this.validate.orderNumber = orderNumber === '' || !!orderNumber ? null : false
    },
    validateCustomerEmail() {
      const email = this.invoiceData.customerEmail
      const emailValid = (this.validate.customerEmail = /^[a-z0-9_\-\.]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i.test(email))
      this.validate.customerEmail =
        email === '' || emailValid || this.invoiceData.customerEmailHide || !this.settings.basic_settings.email.hide ? null : false
    },
    validateCustomerPhone() {
      const phone = this.invoiceData.customerPhone
      const phoneValid = (this.validate.customerPhone = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(phone))
      this.validate.customerPhone =
        phone === '' || !this.settings.basic_settings.phone.hide || this.invoiceData.customerPhoneHide || phoneValid ? null : false
    },
    validateAdditionalData(index) {
      const name = this.invoiceData.additionalData[index].value
      this.validate.additionalData[index].value = !!name ? null : false
    },
    validateCartItemName(index) {
      const name = this.invoiceData.cart[index].name
      this.validate.cart[index].name = !!name ? null : false
    },
    validateCartItemCost(index) {
      const cost = this.invoiceData.cart[index].cost
      this.validate.cart[index].cost = cost > 0 ? null : false
    },
    clearValidateExtendSettings() {
      this.validate.ExtendSettings.credit = null
      this.validate.ExtendSettings.card = null
    },
    copyUrl() {
      const urlToCopy = this.getPaymentUrl()
      navigator.clipboard
        .writeText(urlToCopy)
        .then(() => {
          this.$toast.success('URL успешно скопирован в буфер обмена')
        })
        .catch((err) => {
          this.$toast.error('Не удалось скопировать URL: ', err)
        })
    },
    goToUrl(url) {
      window.open(url, '_blank')
    },
    getPaymentUrl() {
      return `${VUE_APP_SECURE_APP}payment/${this.invoice.id}/`
    },
    goSettings() {
      this.$router.push({ name: 'Invoices', params: { page: 'settings' } })
    },
    async handleMerchantIdChange(newVal) {
      let options = [{ value: 'none', text: '- не выбрано -' }]
      let api_key = ''
      let credit = false
      let gp = false
      let segment = ''
      if (newVal !== 'none') {
        let merchants = this.$store.state.account.hl_merchants
        let merchant = merchants.find((obj) => obj.merchant_id === newVal)
        if (merchant) {
          this.settings.payment_methods_settings.credit.merchant = merchant
          if (merchant.min_order_amount !== undefined && isNumeric(merchant.min_order_amount))
            this.min_order_amount = parseFloat(merchant.min_order_amount.replace(',', '.'))
          if (merchant.max_order_amount !== undefined && isNumeric(merchant.max_order_amount))
            this.max_order_amount = parseFloat(merchant.max_order_amount.replace(',', '.'))
          api_key = merchant.api_key

          if (merchant.use_billing_pricing_models && merchant.use_billing_pricing_models === true) {
            options = await this.$store.dispatch('invoices/getBillingPricingModels', {
              clientId: this.$store.state.account.mandarin.client_id,
              merchantId: merchant.mandarin_merchant_id,
            })
          } else {
            let result = getTermOptions(merchant, gp)
            options = result.options
            gp = result.gp
          }

          credit = !merchant.calc_discount7Seconds
          segment = merchant.segment ? merchant.segment : ''
        }
      }
      this.term_in_months_options = options
      this.term_in_months = []
      this.settings.payment_methods_settings.credit.api_key = api_key
      this.settings.payment_methods_settings.credit.credit = credit
      this.settings.payment_methods_settings.credit.gp = gp
      this.settings.payment_methods_settings.credit.segment = segment
    },
    addCartItem() {
      this.invoiceData.cart.push({ name: '', cost: 0.0, vat: 'None' })
    },
    removeCartItem(index) {
      if (this.invoiceData.cart.length === 1) this.clearCart()
      else this.invoiceData.cart.splice(index, 1)
    },
    clearCart() {
      this.invoiceData.cart = [{ name: '', cost: 0.0, vat: 'None' }]
    },
    clearForm() {
      this.invoiceData.orderNumber = ''
      this.invoiceData.customerEmail = ''
      this.invoiceData.customerPhone = ''
      this.invoiceData.customerEmailHide = false
      this.invoiceData.customerPhoneHide = false
      this.invoiceData.additionalData.forEach((_, index) => {
        this.invoiceData.additionalData[index].value = ''
      })
    },
    async createInvoice() {
      await this.validateAll()

      if (!this.isFormValid()) {
        this.$toast.error('Пожалуйста, заполните все обязательные поля')
        return
      }
      const invoiceData = {
        source: 'merchant_dashboard',
        client_id: this.$store.state.account.mandarin.client_id,
        cart: this.invoiceData.cart.map((item) => ({
          name: item.name,
          cost: item.cost,
          vat: item.vat,
        })),
        payment_settings_id: this.settings.id,
        additional_data: this.invoiceData.additionalData,
        settings_data: {
          clientName: this.$store.state.account.clientData.legal_info.legal_name || '',
          orderNumber: this.invoiceData.orderNumber,
          customerEmail: this.invoiceData.customerEmail,
          customerPhone: this.invoiceData.customerPhone,
          customerEmailHide: this.invoiceData.customerEmailHide || !this.settings.basic_settings.email.hide,
          customerPhoneHide: this.invoiceData.customerPhoneHide || !this.settings.basic_settings.phone.hide,
          paymentMethods: {
            credit: this.settings.payment_methods_settings.credit.enable
              ? {
                  ...this.settings.payment_methods_settings.credit,
                  term_in_months: this.term_in_months,
                }
              : null,
            card: this.settings.payment_methods_settings.card.enable
              ? {
                  ...this.settings.payment_methods_settings.card,
                }
              : null,
            card2: this.settings.payment_methods_settings.card2.enable
              ? {
                  ...this.settings.payment_methods_settings.card2,
                }
              : null,
          },
          additionalSettings: {
            offerLink: this.settings.mandatory_fields_settings.offer_link,
            redirectUrl: this.settings.mandatory_fields_settings.redirect_url,
            redirectFailedUrl: this.settings.mandatory_fields_settings.redirect_failed_url,
            logo: this.settings.mandatory_fields_settings.logo,
          },
          fiscalizationSettings: this.settings.fiscalization_settings.enable
            ? {
                enable: this.settings.fiscalization_settings.enable,
                taxationSystem: this.settings.fiscalization_settings.taxationSystem,
                vat: this.settings.fiscalization_settings.vat,
                calculationMethod: this.settings.fiscalization_settings.calculationMethod,
                paymentSubject: this.settings.fiscalization_settings.paymentSubject,
                description: this.settings.fiscalization_settings.description,
              }
            : null,
        },
      }

      await this.$store.dispatch('invoices/createInvoice', invoiceData).then((responseData) => {
        this.$store.dispatch('pagerStd/updateCurrentTableState', 'default')
      })
    },
    fillFormWithInvoiceData(invoice) {
      // Заполнение данных заказа
      this.invoiceData.orderNumber = invoice.settings_data.orderNumber
      this.invoiceData.customerEmail = invoice.settings_data.customerEmail
      this.invoiceData.customerPhone = invoice.settings_data.customerPhone
      this.invoiceData.customerEmailHide = invoice.settings_data.customerEmailHide
      this.invoiceData.customerPhoneHide = invoice.settings_data.customerPhoneHide

      // Заполнение корзины
      this.invoiceData.cart = invoice.cart.map((item) => ({
        name: item.name,
        cost: item.cost,
        vat: item.vat,
      }))

      // Заполнение настроек способов оплаты
      if (invoice.settings_data.paymentMethods) {
        this.settings.payment_methods_settings.credit =
          invoice.settings_data.paymentMethods.credit || this.settings.payment_methods_settings.credit
        if (!invoice.settings_data.paymentMethods.credit) this.settings.payment_methods_settings.credit.enable = false
        this.settings.payment_methods_settings.card =
          invoice.settings_data.paymentMethods.card || this.settings.payment_methods_settings.card
        if (!invoice.settings_data.paymentMethods.card) this.settings.payment_methods_settings.card.enable = false
        this.settings.payment_methods_settings.card2 =
          invoice.settings_data.paymentMethods.card2 || this.settings.payment_methods_settings.card2
        if (!invoice.settings_data.paymentMethods.card2) this.settings.payment_methods_settings.card2.enable = false
        this.term_in_months = invoice.settings_data.paymentMethods.credit ? invoice.settings_data.paymentMethods.credit.term_in_months : []
      }
      // Допполя
      this.invoiceData.additionalData = invoice.additional_data || []
      this.validate.additionalData = invoice.additional_data.map((field) => {
        return { value: null }
      })
    },
    updateMerchantName(newVal) {
      if (newVal !== undefined && this.settings) {
        console.log(newVal)
        let merchants_name = this.$store.state.account.merchants_name
        let index = this.$store.state.account.merchants.indexOf(newVal)
        if (index !== -1) {
          console.log(merchants_name[index])
          this.settings.payment_methods_settings.card.merchant_name = merchants_name[index]
          this.settings.payment_methods_settings.card2.merchant_name = merchants_name[index]
        }
      }
    },
  },
  watch: {
    invoiceData: {
      handler(newVal) {
        if (this.invoice) this.$store.dispatch('invoices/clearInvoice')
        if (newVal.customerEmailHide) this.invoiceData.customerEmail = ''
        if (newVal.customerPhoneHide) this.invoiceData.customerPhone = ''
        if (newVal.cart) this.validate.cart = newVal.cart.map(() => ({ name: null, cost: null }))
      },
      deep: true,
    },
    '$store.state.account.merchants_name': {
      immediate: true,
      handler(newVal) {
        if (this.settings && this.settings.payment_methods_settings.card.merchant_id)
          this.updateMerchantName(this.settings.payment_methods_settings.card.merchant_id)
        if (this.settings && this.settings.payment_methods_settings.card2.merchant_id)
          this.updateMerchantName(this.settings.payment_methods_settings.card2.merchant_id)
      },
    },
    'settings.payment_methods_settings.card.merchant_id': {
      immediate: true,
      handler(newVal) {
        this.updateMerchantName(newVal)
      },
    },
    'settings.payment_methods_settings.card2.merchant_id': {
      immediate: true,
      handler(newVal) {
        this.updateMerchantName(newVal)
      },
    },
    '$store.state.invoices.invoiceCopy': {
      immediate: true,
      handler(newVal) {
        if (newVal && this.settings) {
          this.fillFormWithInvoiceData(newVal)
        }
      },
    },
    '$store.state.invoices.accountSettings': {
      immediate: true,
      handler() {
        if (this.$store.state.invoices.accountSettings) {
          this.settings = this.$store.state.invoices.accountSettings
          if (this.settings) {
            this.enableInfo =
              !this.settings.payment_methods_settings.credit.enable &&
              !this.settings.payment_methods_settings.card.enable &&
              !this.settings.payment_methods_settings.card2.enable
          }
          this.invoiceData.additionalData = this.settings.additional_fields_settings.map((field) => {
            return { name: field.name, value: '' }
          })
          this.validate.additionalData = this.settings.additional_fields_settings.map((field) => {
            return { value: null }
          })
        }
      },
    },
    '$store.state.account.hl_merchants': {
      immediate: true,
      handler() {
        let merchants = this.$store.state.account.hl_merchants
        let options = []
        let options_term = [{ value: 'none', text: '- не выбрано -' }]
        if (Array.isArray(merchants)) {
          merchants.forEach(async (merchant) => {
            if (merchant.is_active) {
              options.push({ value: merchant.merchant_id, text: merchant.full_name })
              if (
                this.settings &&
                this.settings.payment_methods_settings &&
                this.settings.payment_methods_settings.credit &&
                merchant.merchant_id === this.settings.payment_methods_settings.credit.product_id
              ) {
                this.settings.payment_methods_settings.credit.merchant = merchant
                if (merchant.min_order_amount !== undefined && isNumeric(merchant.min_order_amount))
                  this.min_order_amount = parseFloat(merchant.min_order_amount.replace(',', '.'))
                if (merchant.max_order_amount !== undefined && isNumeric(merchant.max_order_amount))
                  this.max_order_amount = parseFloat(merchant.max_order_amount.replace(',', '.'))

                if (merchant.use_billing_pricing_models && merchant.use_billing_pricing_models === true) {
                  options_term = await this.$store.dispatch('invoices/getBillingPricingModels', {
                    clientId: this.$store.state.account.mandarin.client_id,
                    merchantId: merchant.mandarin_merchant_id,
                  })
                } else {
                  let result = getTermOptions(merchant, this.settings.payment_methods_settings.credit.gp)
                  options_term = result.options
                  this.settings.payment_methods_settings.credit.gp = result.gp
                }

                this.term_in_months_options = options_term
              }
            }
          })
        }
        this.hl_merchants_options = options
        this.term_in_months =
          this.settings && this.settings.payment_methods_settings && this.settings.payment_methods_settings.credit
            ? this.settings.payment_methods_settings.credit.term
            : default_options
      },
    },
  },
}
</script>
<style scoped>
.form-control[readonly]:hover {
  cursor: pointer;
}

.custom-checkbox {
  font-size: 12px;
  margin-top: 5px;
}

@media (max-width: 767px) {
  .w-50,
  .w-25 {
    width: 100% !important;
  }
}
</style>
